import { useState } from "react";
import * as Sentry from "@sentry/browser";
import { AxiosError } from "axios";
import { useEffectOnce } from "react-use";
import { EVENTS, optimizelyClient, track } from "@gemini-ui/analytics";
import { OPTIMIZELY_FEATURE_FLAGS } from "@gemini-ui/constants/featureFlags";
import { AuthenticationRequest } from "@gemini-ui/constants/initialData";
import { usePasskeyAuth } from "@gemini-ui/pages/Authy/AuthyForm/usePasskeyAuth";
import axios from "@gemini-ui/services/axios";

export enum PasskeyErrorType {
  PasskeySigninDisabled = "passkeySigninDisabled",
  ErrorProcessing = "errorProcessing",
}

export const usePasskeySignin = (redirect: string) => {
  const [authRequest, setAuthRequest] = useState<AuthenticationRequest>(null);
  const [errorType, setErrorType] = useState<PasskeyErrorType>(null);
  const { initiateAuth } = usePasskeyAuth(true);

  const isPasswordlessEnabled = optimizelyClient.isFeatureEnabled(
    OPTIMIZELY_FEATURE_FLAGS.WEB_CREATE_PASSKEYS_PASSWORDLESS
  );
  const startAuthProcess = (authRequest: AuthenticationRequest) => {
    const publicKeyCredentialRequestOptions = authRequest.assertion.assertion.publicKeyCredentialRequestOptions;
    const authSignedRequest = authRequest.signed;
    initiateAuth({
      onSuccess: handleSuccess,
      onError: handleError,
      publicKeyCredentialRequestOptions,
      authSignedRequest,
      redirect,
    });
  };

  const fetchProof = async (triggerAuth = false) => {
    try {
      const response = await axios.get<AuthenticationRequest>("/signin/passwordless");
      setAuthRequest(response.data);
      // support retrying and opening webauthn right away on success
      if (triggerAuth) {
        startAuthProcess(response.data);
      }
    } catch (e) {
      track(EVENTS.SIGNIN_WITH_PASSKEY_ERROR_FETCHING_PROOF.name, {
        [EVENTS.SIGNIN_WITH_PASSKEY_ERROR_FETCHING_PROOF.properties.Error]: e,
      });
      Sentry.captureException(e);
    }
  };

  const retrySignin = () => {
    setErrorType(null);
    fetchProof(true);
  };

  const handleSuccess = (data: { redirect: string }) => {
    track(EVENTS.SIGNIN_WITH_PASSKEY_SUCCESS);
    window.location.assign(data?.redirect || "/");
  };

  const handleError = (e: AxiosError<{ error: string }> | DOMException) => {
    track(EVENTS.SIGNIN_WITH_PASSKEY_ERROR.name, { [EVENTS.SIGNIN_WITH_PASSKEY_ERROR.properties.Error]: e.message });
    if (e instanceof AxiosError && e.response.data?.error === PasskeyErrorType.PasskeySigninDisabled) {
      setErrorType(PasskeyErrorType.PasskeySigninDisabled);
    } else {
      setErrorType(PasskeyErrorType.ErrorProcessing);
    }
  };

  const startSignin = () => {
    if (authRequest) {
      track(EVENTS.SIGNIN_WITH_PASSKEY_CLICKED);
      startAuthProcess(authRequest);
    } else {
      // only display error alert when invoking the passkey flow
      setErrorType(PasskeyErrorType.ErrorProcessing);
    }
  };
  // fetch on mount to open webauthn if clicked right away
  useEffectOnce(() => {
    if (isPasswordlessEnabled) {
      fetchProof(false);
    }
  });

  return { startSignin, errorType, setErrorType, retrySignin };
};
