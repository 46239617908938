
    import { render as __gemini_ui_render } from "@gemini-ui/renderer";
    function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
/** @jsx jsx */
import * as React from "react";
import { jsx } from "@emotion/react";
import * as Sentry from "@sentry/browser";
// eslint-disable-next-line no-restricted-imports
import { Formik } from "formik";
import queryString from "query-string";
import { AlertContext } from "@gemini-ui/components/GlobalAlert/AlertProvider";
import { AlertTypes } from "@gemini-ui/components/GlobalAlert/constants";
import _SupportLink from "@gemini-ui/components/SupportLink";
import OnboardingLayout from "@gemini-ui/pages/register/OnboardingLayout";
import { signInFormValidation } from "@gemini-ui/pages/SignIn/formValidation";
import { formatErrorMsg } from "@gemini-ui/pages/SignIn/helpers";
import RightNav from "@gemini-ui/pages/SignIn/rightNav";
import { SandboxMessage } from "@gemini-ui/pages/SignIn/SandboxMessage";
import { SignInForm } from "@gemini-ui/pages/SignIn/SignInForm";
import { handleExceptionWithRedirect, makeSignInRequest } from "@gemini-ui/pages/SignIn/utils";
import { SENTRY_TAGS, SENTRY_TEAMS } from "@gemini-ui/sentry/teams";
import { getError, getFormErrors } from "@gemini-ui/utils/error";
import { defineMessage, withIntl } from "@gemini-ui/utils/intl";
import storage from "@gemini-ui/utils/storage";
const registerUrl = jsRoutes.controllers.register.RegisterController.get().url;
class SignIn extends React.Component {
  constructor() {
    super(...arguments);
    _defineProperty(this, "recaptcha", void 0);
    _defineProperty(this, "extractClientId", url => {
      const getSearch = u => u.includes("?") && u.split("?")[1];
      const getParams = s => queryString.parse(decodeURIComponent(s));
      const getOAuthClientId = params => ({
        ...params,
        ...(params.redirect ? getParams(getSearch(params.redirect)) : {})
      }).client_id;
      return getOAuthClientId(getParams(getSearch(url)));
    });
    _defineProperty(this, "formatRegisterUrl", (registerUrl, oauthClientId) => {
      if (oauthClientId) {
        return `${registerUrl}?clientId=${oauthClientId}`;
      }
      return registerUrl;
    });
    _defineProperty(this, "handleSubmit", async (values, _ref) => {
      let {
        setSubmitting,
        setErrors,
        setFieldValue
      } = _ref;
      const {
        intl,
        redirect
      } = this.props;
      setSubmitting(true);
      try {
        await makeSignInRequest(values, redirect);
      } catch (exception) {
        if (!handleExceptionWithRedirect(exception, values.email)) {
          const formErrors = getFormErrors(exception);
          if (formErrors) {
            setErrors({
              password: formatErrorMsg(formErrors, intl)
            });
          } else {
            const error = getError(exception);
            this.context.showAlert({
              id: "submission-error",
              type: AlertTypes.ERROR,
              message: error
            });
            Sentry.captureException(exception);
          }
        }
        setSubmitting(false);
        this.recaptcha.reset();
        // don't let formik re-run validation (it removes the field error)
        setFieldValue("g-recaptcha-response-v2", "", false);
        setFieldValue("g-recaptcha-response-v3", "", false);
      }
    });
    _defineProperty(this, "handleRecaptcha", ref => this.recaptcha = ref);
    _defineProperty(this, "onRecaptchaResolve", formikMethods => () => {
      const {
        setFieldValue
      } = formikMethods;
      const {
        siteKeyV3,
        intl
      } = this.props;
      if (siteKeyV3) {
        this.recaptcha.executeV3().then(token => {
          setFieldValue("g-recaptcha-response-v2", this.recaptcha.getResponse());
          setFieldValue("g-recaptcha-response-v3", token);
        }).catch(e => {
          this.context.showAlert({
            type: AlertTypes.ERROR,
            message: jsx(React.Fragment, null, intl.formatMessage(defineMessage({
              id: "QEXwV0",
              defaultMessage: [{
                "type": 0,
                "value": "Unable to verify device, please "
              }, {
                "type": 8,
                "value": "SupportLink",
                "children": []
              }, {
                "type": 0,
                "value": "."
              }]
            }), {
              SupportLink: () => jsx(_SupportLink, null)
            })),
            id: "reset-password-error"
          });
          Sentry.captureMessage(`Sign in form: Something went wrong with the recaptcha v3 execution on the client error: ${e}`, Sentry.Severity.Error);
        });
      } else {
        setFieldValue("g-recaptcha-response-v2", this.recaptcha.getResponse());
      }
    });
  }
  componentDidMount() {
    Sentry.setTag(SENTRY_TAGS.Team, SENTRY_TEAMS.ConsumerExperience);
    const notification = storage.getFlashNotification();
    if (notification) {
      this.context.showAlert({
        type: notification.type,
        message: notification.message
      });
    }
  }
  title() {
    const {
      intl
    } = this.props;
    const {
      oauthClient
    } = this.props;
    if (oauthClient) {
      return intl.formatMessage(defineMessage({
        id: "3HZjhY",
        defaultMessage: [{
          "type": 0,
          "value": "Connect "
        }, {
          "type": 1,
          "value": "oauthClientName"
        }, {
          "type": 0,
          "value": " with your Gemini account"
        }]
      }), {
        oauthClientName: oauthClient.name
      });
    } else {
      const {
        sandboxMode
      } = this.props;
      return sandboxMode ? intl.formatMessage({
        id: "r6G0F2",
        defaultMessage: [{
          "type": 0,
          "value": "Gemini sandbox"
        }]
      }) : intl.formatMessage({
        id: "SQJto2",
        defaultMessage: [{
          "type": 0,
          "value": "Sign in"
        }]
      });
    }
  }
  render() {
    const {
      intl,
      siteKeyV2,
      siteKeyV3
    } = this.props;
    const {
      sandboxMode,
      signInForm,
      oauthClient,
      redirect
    } = this.props;
    const verboseRegister = oauthClient != null;
    const query = queryString.parse(decodeURIComponent(window.location.search));
    const referralCode = signInForm.values.referral ? signInForm.values.referral : query.referral;
    const oauthClientId = this.extractClientId(window.location.href);
    const formattedRegisterUrl = this.formatRegisterUrl(registerUrl, oauthClientId);
    return jsx(OnboardingLayout, {
      title: this.title(),
      rightHeader: jsx(RightNav, {
        oauthClientId: oauthClientId
      })
    }, sandboxMode && jsx(SandboxMessage, null), jsx(Formik, {
      initialValues: {
        csrfToken: signInForm.csrfToken,
        email: signInForm.values.email,
        password: signInForm.values.password,
        rememberEmail: signInForm.values.rememberEmail,
        referral: referralCode,
        oauthClientId,
        ["g-recaptcha-response-v2"]: signInForm.values["validationIds.g-recaptcha-response-v2"],
        ["g-recaptcha-response-v3"]: signInForm.values["validationIds.g-recaptcha-response-v3"]
      },
      validationSchema: signInFormValidation(intl),
      onSubmit: this.handleSubmit,
      render: _ref2 => {
        let {
          values,
          errors,
          touched,
          handleChange,
          isSubmitting,
          handleSubmit,
          setFieldValue
        } = _ref2;
        return jsx(SignInForm, {
          handleSubmit: handleSubmit,
          values: values,
          errors: errors,
          touched: touched,
          handleChange: handleChange,
          isSubmitting: isSubmitting,
          setFieldValue: setFieldValue,
          siteKeyV2: siteKeyV2,
          siteKeyV3: siteKeyV3,
          setRecaptcha: this.handleRecaptcha,
          onRecaptchaResolve: this.onRecaptchaResolve,
          redirect: redirect
        });
      }
    }), verboseRegister && jsx(React.Fragment, null, jsx("hr", {
      style: {
        marginBottom: 50,
        marginTop: 50
      }
    }), jsx("p", {
      className: "FieldLabel"
    }, intl.formatMessage({
      id: "mdnhvE",
      defaultMessage: [{
        "type": 0,
        "value": "Need a Gemini account?"
      }]
    })), jsx("p", null, intl.formatMessage({
      id: "rpafF8",
      defaultMessage: [{
        "type": 0,
        "value": "Sign up today for instant access to Gemini’s global exchange, the most trusted place to buy and sell cryptocurrencies"
      }]
    })), jsx("a", {
      href: formattedRegisterUrl
    }, intl.formatMessage({
      id: "gggFqg",
      defaultMessage: [{
        "type": 0,
        "value": "Sign up here →"
      }]
    }))));
  }
}
_defineProperty(SignIn, "contextType", AlertContext);
SignIn.displayName = "SignIn";
export const RenderComponent = withIntl(SignIn);
export default withIntl(SignIn);
    __gemini_ui_render(RenderComponent);
  