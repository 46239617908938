import styled from "@emotion/styled";
import { Button, Colors, mediaQuery, Spacing, Text } from "@gemini-ui/design-system";

export const ImplicitLegalText = styled(Text.Body)`
  margin-top: ${Spacing.scale[3]};
  padding-bottom: ${Spacing.scale[1]};
  color: ${({ theme }) => theme.colorScheme.content.secondary};
`;

export const SandboxMessageContainer = styled("div")`
  display: flex;
  padding: ${Spacing.scale[2]} ${Spacing.scale[2.5]} ${Spacing.scale[2]} ${Spacing.scale[2]};
  width: 100%;
  background-color: ${Colors.gray[100]};
  line-height: 1.6;
  margin-bottom: ${Spacing.scale[4]};
`;

export const SignInButtonGroup = styled(Button.Group)`
  @media ${mediaQuery.mobileXsDown} {
    align-items: start;
  }
`;

export const ForgotPasswordLink = styled(Text.Link)`
  @media ${mediaQuery.mobileXsDown} {
    margin-top: ${Spacing.scale[2]};
  }
`;
