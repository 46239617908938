import styled from "@emotion/styled";
import { Colors, Spacing } from "@gemini-ui/design-system";
import colorVariables from "@gemini-ui/styles/colorVariables";
import { BREAKPOINTS } from "@gemini-ui/utils/breakpoints";

export const DidYouKnowContainer = styled("div")`
  display: flex;
  position: relative;
  flex-direction: column;
  padding: ${Spacing.scale[3]};
  box-sizing: border-box;
  height: 190px;
  width: 490px;
  border: 1px solid ${Colors.gray[100]};
  border-radius: 8px;

  @media ${BREAKPOINTS.tabletDownLarge} {
    width: auto;
    height: auto;
  }

  @media ${BREAKPOINTS.mobileDownLarge} {
    margin-bottom: ${Spacing.scale[1.5]};
  }
`;

export const DidYouKnowHeader = styled("div")`
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 20px;
  color: ${Colors.gray[600]};
`;

export const DidYouKnowTitle = styled(DidYouKnowHeader)`
  font-size: 20px;
  padding-top: 4px;
  line-height: 27px;
  color: ${colorVariables.blue["11"]};
`;

export const DidYouKnowContent = styled("div")`
  color: ${colorVariables.blue["11"]};
  font-size: 12px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 20px;
  padding-bottom: ${Spacing.scale[2]};
  padding-top: ${Spacing.scale[2]};
`;
