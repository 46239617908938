import * as Yup from "yup";
import { IntlShape } from "@gemini-ui/utils/intl";

export const signInFormValidation = (intl: IntlShape) =>
  Yup.object().shape({
    email: Yup.string()
      .required(intl.formatMessage({ defaultMessage: "Please enter a valid email address, like user@gmail.com." }))
      .email(intl.formatMessage({ defaultMessage: "Please enter a valid email address, like user@gmail.com." })),
    password: Yup.string().required(intl.formatMessage({ defaultMessage: "Please enter your password" })),
  });
