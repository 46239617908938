import * as React from "react";
import { Text } from "@gemini-ui/design-system";
import { ReactComponent as Exclamation } from "@gemini-ui/images/icons/exclamation.svg";
import { SandboxMessageContainer } from "@gemini-ui/pages/SignIn/styles";
import { defineMessage, useIntl } from "@gemini-ui/utils/intl";

export const SandboxMessage = React.memo(() => {
  const { intl } = useIntl();

  return (
    <SandboxMessageContainer>
      <Exclamation style={{ margin: "4px 8px 0 0", width: "29px" }} />
      <div data-testid="sandbox-message">
        {intl.formatMessage(
          defineMessage({
            defaultMessage:
              "The sandbox is for demonstration purposes. To log in to Gemini, <TextLink>click here</TextLink>.",
          }),
          {
            TextLink: (v: React.ReactNode) => <Text.Link href="https://exchange.gemini.com">{v}</Text.Link>,
          }
        )}
      </div>
    </SandboxMessageContainer>
  );
});
