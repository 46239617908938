/** @jsx jsx */
import React from "react";
import { jsx } from "@emotion/react";
import { Button } from "@gemini-ui/design-system";
import {
  DidYouKnowContainer,
  DidYouKnowContent,
  DidYouKnowHeader,
  DidYouKnowTitle,
} from "@gemini-ui/pages/Custody/Dashboard/DidYouKnow/styles";
import { ReactComponent as CloseIcon } from "@gemini-ui/pages/Custody/images/Close-24px.svg";
import { useIntl } from "@gemini-ui/utils/intl";
import storage from "@gemini-ui/utils/storage";

interface DidYouKnowProps {
  title: React.ReactNode;
  link: string;
  showInstantTradeCard: boolean;
  setShowInstantTradeCard: (shouldShow: boolean) => void;
}

export const SHOW_INSTANT_TRADE_CARD = "showInstantTradeCard";

export const DidYouKnow: React.FC<React.PropsWithChildren<DidYouKnowProps>> = props => {
  const { intl } = useIntl();

  const hideMe = () => {
    storage.set(SHOW_INSTANT_TRADE_CARD, "false");
    props.setShowInstantTradeCard(false);
  };
  return props.showInstantTradeCard ? (
    <DidYouKnowContainer>
      <div css={{ position: "absolute", top: 19, right: 19, cursor: "pointer" }}>
        <CloseIcon onClick={hideMe} />
      </div>
      <DidYouKnowHeader>
        {intl.formatMessage({
          defaultMessage: "Did you know?",
        })}
      </DidYouKnowHeader>
      <DidYouKnowTitle>{props.title}</DidYouKnowTitle>
      <DidYouKnowContent>{props.children}</DidYouKnowContent>
      <Button.Tertiary
        data-testid="instant-trade-link"
        display="inline"
        size="sm"
        underline
        flush
        href={props.link}
        cta={intl.formatMessage({
          defaultMessage: "Learn more",
        })}
        css={{ margin: "0 auto 0 0" }}
      />
    </DidYouKnowContainer>
  ) : null;
};
